import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap } from 'rxjs';

import {
	Participated,
	PillItemParticipation,
	StatusPillColors,
	StatusPillDisplay
} from '@yuno/api/interface';

import { PostService } from '../services';

type ParticipationPostState = {
	data: Participated[];
	filter: string | undefined;
	preFilter: PillItemParticipation['key'];
	isLoading: boolean;
};

const initialState: ParticipationPostState = {
	data: [],
	filter: undefined,
	preFilter: 'all',
	isLoading: false
};

export type getPostsData = {
	appId: string;
	modelId: string;
};

const updateData = (data: Participated[], update: Participated) => {
	return data.map(f => (f._id === update._id ? update : f));
};

const counter = (data: Participated[]) => {
	return {
		all: data.length,
		new: data.filter(i => i.state === 'new').length,
		onhold: data.filter(i => i.state === 'onhold').length,
		answered: data.filter(i => i.state === 'answered').length,
		published: data.filter(i => i.state === 'published').length,
		archived: data.filter(i => i.state === 'archived').length,
		unverified: data.filter(i => i.state === 'unverified').length
	};
};

const filteredData = (
	data: Participated[],
	prefilter: PillItemParticipation['key']
): Participated[] => {
	const filterFunctions = {
		new: (item: Participated) => item.state === 'new',
		onhold: (item: Participated) => item.state === 'onhold',
		answered: (item: Participated) => item.state === 'answered',
		published: (item: Participated) => item.state === 'published',
		archived: (item: Participated) => item.state === 'archived',
		unverified: (item: Participated) => item.state === 'unverified',
		all: (item: Participated) => true
	};

	return data.filter(filterFunctions[prefilter]);
};

const activeFilterData = (data: Participated[], key: PillItemParticipation['key']) => {
	return {
		display: StatusPillDisplay[key],
		count: filteredData(data, key).length,
		color: StatusPillColors[key]
	};
};

export const PostStore = signalStore(
	{ providedIn: 'root', protectedState: false },
	withState(initialState),
	withComputed(store => ({
		selected: computed(() => store.data().find(f => store.filter() === f._id)),
		filtered: computed((): Participated[] => filteredData(store.data(), store.preFilter())),
		counters: computed(() => counter(store.data())),
		activeFilter: computed(() => activeFilterData(store.data(), store.preFilter())),
		activeFilterCount: computed(() => filteredData(store.data(), store.preFilter()).length)
	})),
	withMethods((state, service = inject(PostService)) => ({
		reset: () => patchState(state, initialState),
		resetFilter: () => {
			patchState(state, { filter: undefined });
		},
		setFilter(filter: string) {
			patchState(state, { filter });
		},

		resetPreFilter: () => {
			patchState(state, { preFilter: undefined });
		},
		setPreFilter(preFilter: PillItemParticipation['key']) {
			patchState(state, { preFilter });
		},

		getPosts: rxMethod<getPostsData>(posts =>
			posts.pipe(
				tap(() => patchState(state, { isLoading: true })),
				switchMap(postsData =>
					service.getPosts(postsData.appId, postsData.modelId).pipe(
						tapResponse({
							next: data => {
								patchState(state, { data, isLoading: false });
							},
							error: () => {
								patchState(state, { data: initialState.data, isLoading: false });
							}
						})
					)
				)
			)
		),
		onPublish: rxMethod<{ id: string; appId: string }>(
			pipe(
				switchMap(({ id, appId }) =>
					service.onPublish(id, appId).pipe(
						tapResponse({
							next: data => {
								patchState(state, {
									data: updateData(state.data(), data),
									isLoading: false
								});
							},
							error: () =>
								patchState(state, {
									data: state.data() || [],
									isLoading: false
								})
						})
					)
				)
			)
		),
		sendAnswer: rxMethod<{ id: string; appId: string }>(
			pipe(
				switchMap(({ id, appId }) =>
					service.sendAnswer(id, appId).pipe(
						tapResponse({
							next: data => {
								patchState(state, {
									data: updateData(state.data(), data),
									isLoading: false
								});
							},
							error: () =>
								patchState(state, {
									data: state.data() || [],
									isLoading: false
								})
						})
					)
				)
			)
		),
		updateAnswer: rxMethod<{ id: string; appId: string; content: string }>(
			pipe(
				switchMap(({ id, appId, content }) =>
					service.updateAnswer(id, appId, content).pipe(
						tapResponse({
							next: data => {
								patchState(state, {
									data: updateData(state.data(), data),
									isLoading: false
								});
							},
							error: () =>
								patchState(state, {
									data: state.data() || [],
									isLoading: false
								})
						})
					)
				)
			)
		),
		updateReaction: rxMethod<{ id: string; appId: string; content: string }>(
			pipe(
				switchMap(({ id, appId, content }) =>
					service.updateReaction(id, appId, content).pipe(
						tapResponse({
							next: data => {
								patchState(state, {
									data: updateData(state.data(), data),
									isLoading: false
								});
							},
							error: () =>
								patchState(state, {
									data: state.data() || [],
									isLoading: false
								})
						})
					)
				)
			)
		),
		onHold: rxMethod<{ id: string; appId: string }>(
			pipe(
				switchMap(({ id, appId }) =>
					service.onHold(id, appId).pipe(
						tapResponse({
							next: data => {
								patchState(state, {
									data: updateData(state.data(), data),
									isLoading: false
								});
							},
							error: () =>
								patchState(state, {
									data: state.data() || [],
									isLoading: false
								})
						})
					)
				)
			)
		),
		toArchive: rxMethod<{ id: string; appId: string }>(
			pipe(
				switchMap(({ id, appId }) =>
					service.toArchive(id, appId).pipe(
						tapResponse({
							next: data => {
								patchState(state, {
									data: updateData(state.data(), data),
									isLoading: false
								});
							},
							error: () =>
								patchState(state, {
									data: state.data() || [],
									isLoading: false
								})
						})
					)
				)
			)
		)
	}))
);
