import { computed, inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { switchMap, tap } from 'rxjs';

import { ParticipationModelDto } from '@yuno/api/interface';

import { ModelService } from '../services';

type ParticipationModelState = {
	data: ParticipationModelDto[];
	selectedModel: ParticipationModelDto | null;
	isLoading: boolean;
	dataLoaded: boolean;
};

const initialState: ParticipationModelState = {
	data: [],
	selectedModel: null,
	isLoading: false,
	dataLoaded: false
};

export const ModelStore = signalStore(
	{ providedIn: 'root', protectedState: false },
	withState(initialState),
	withComputed(({ selectedModel }) => ({
		id: computed(() => selectedModel()?.id),
		leftColumn: computed(() => selectedModel()?.reactionColumnLeft),
		rightColumn: computed(() => selectedModel()?.reactionColumnRight)
	})),
	withMethods((state, service = inject(ModelService)) => ({
		reset: () => patchState(state, initialState),
		selectModel(query: string) {
			const selectedModel = state.data().find(model => model._id === query) || null;
			patchState(state, {
				selectedModel
			});
		},
		getApp: rxMethod<string>(appId =>
			appId.pipe(
				tap(() => patchState(state, { isLoading: true, dataLoaded: false })),
				switchMap(appId =>
					service.getApp(appId).pipe(
						tapResponse({
							next: data => {
								patchState(state, { data, isLoading: false, dataLoaded: true });
							},
							error: () => {
								patchState(state, {
									data: initialState.data,
									isLoading: false,
									dataLoaded: true
								});
							}
						})
					)
				)
			)
		)
	}))
);
